import {
  ChatCircleDots,
  Checks,
  Clock,
  Envelope,
  Flag,
  Lock,
  Scroll,
  SealCheck,
  Video,
} from "@phosphor-icons/react";
import SidebarItem from "./SidebarItem";

export default function AdminMenu({ shrunk }) {
  return (
    <>
      <SidebarItem
        icon={<Video />}
        title="Treinamentos"
        href="/treinamentos"
        {...{ shrunk }}
      />

      <SidebarItem icon={<Envelope />} title="Contatos" {...{ shrunk }}>
        <SidebarItem
          icon={<Clock />}
          title="Aguardando resposta"
          href="/contatos/aguardando-resposta"
          {...{ shrunk }}
        />
        <SidebarItem
          icon={<Checks />}
          title="Respondidos"
          href="/contatos/respondidos"
          {...{ shrunk }}
        />
      </SidebarItem>

      <SidebarItem icon={<Flag />} title="Denúncias" {...{ shrunk }}>
        <SidebarItem
          icon={<Clock />}
          title="Aguardando resposta"
          href="/denuncias/aguardando-resposta"
          {...{ shrunk }}
        />
        <SidebarItem
          icon={<Checks />}
          title="Respondidos"
          href="/denuncias/respondidos"
          {...{ shrunk }}
        />
      </SidebarItem>

      <SidebarItem
        icon={<ChatCircleDots />}
        title="Depoimentos"
        href="/depoimentos"
        {...{ shrunk }}
      />

      <SidebarItem
        icon={<SealCheck />}
        title="Clientes"
        href="/clientes"
        {...{ shrunk }}
      />

      <SidebarItem
        icon={<Lock />}
        title="Política de privacidade"
        href="/politica-de-privacidade"
        {...{ shrunk }}
      />

      <SidebarItem
        icon={<Scroll />}
        title="Termos de uso"
        href="/termos-de-uso"
        {...{ shrunk }}
      />
    </>
  );
}
