import CustomInput from "components/CustomInput";
import Modal from "components/Modal";
import { toast } from "react-toastify";
import { post } from "utilities/requests";
import form_styles from "components/SpecificStyles/form.module.css";
import { STORAGE_URL } from "utilities/variables";

export default function AdminTreinamentosModal({
  visible,
  setOpen,
  action,
  new_product,
  setNewProduct,
  refetch,
}) {
  console.log(new_product);
  const handleAddProduct = async () => {
    const formData = new FormData();
    formData.append("name", new_product.name);
    formData.append("occupation", new_product.occupation);
    formData.append("text", new_product.text);
    formData.append("image", new_product.image_file);

    const res = await post("testimonials/add", formData);

    if (res.httpCode === 200) {
      refetch();
      setOpen(false);
      toast.success("Depoimento adicionado com sucesso");
    } else {
      toast.error(res.message || "Erro ao adicionar o depoimento");
    }
  };

  const handleEditProduct = async () => {
    const formData = new FormData();
    formData.append("id", new_product.id);
    formData.append("name", new_product.name);
    formData.append("occupation", new_product.occupation);
    formData.append("text", new_product.text);
    formData.append("image", new_product.image_file);

    const res = await post(`testimonials/update`, formData);

    if (res.httpCode === 200) {
      refetch();
      setOpen(false);
      toast.success("Depoimento editado com sucesso");
    } else {
      toast.error(res.message || "Erro ao editar o depoimento");
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setNewProduct({
        ...new_product,
        image_url: reader.result,
        image_file: file,
      });
    };
  };

  return (
    <Modal
      title={action === "add" ? "Adicionar depoimento" : "Editar depoimento"}
      open={visible}
      setOpen={setOpen}
      maxWidth="60rem"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          alignItems: "flex-end",
          width: "100%",
        }}
        className={form_styles.customFormStyle}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <label
              htmlFor="imagem-depoimento-id"
              style={{
                width: "10rem",
                margin: "0 auto",
                aspectRatio: "1/1",
                borderRadius: "50%",
                overflow: "hidden",
                backgroundColor: "#F2F2F2",
                border: "2px dashed #ccc",
                cursor: "pointer",
              }}
            >
              <img
                src={
                  new_product?.image_path
                    ? STORAGE_URL + new_product?.image_path
                    : new_product?.image_url
                }
                alt="Clique para adicionar a imagem"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              <input
                type="file"
                accept="image/*"
                name="imagem-depoimento-id"
                id="imagem-depoimento-id"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "100%",
              }}
            >
              <CustomInput
                placeholder="Nome"
                value={new_product.name}
                onChangeTarget={(e) =>
                  setNewProduct({ ...new_product, name: e.target.value })
                }
              />
              <CustomInput
                placeholder="Ocupação"
                value={new_product.occupation}
                onChangeTarget={(e) =>
                  setNewProduct({ ...new_product, occupation: e.target.value })
                }
              />
            </div>
          </div>

          <textarea
            placeholder="Mensagem"
            value={new_product.text}
            onChange={(e) =>
              setNewProduct({ ...new_product, text: e.target.value })
            }
          />
        </div>

        <input
          type="submit"
          value={
            action === "add" ? "Adicionar depoimento" : "Editar depoimento"
          }
          onClick={() => {
            if (action === "add") {
              handleAddProduct();
            } else {
              handleEditProduct();
            }
          }}
          style={{
            width: "max-content",
          }}
        />
      </div>
    </Modal>
  );
}
