import CustomText from "components/CustomText";
import AdminContatoTable from "./ContatoTable";

export default function AdminContato() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        overflowY: "auto",
        padding: "3rem 2rem",
      }}
    >
      <CustomText text="Contatos" size="1.4rem" bold />
      <CustomText
        text="Aqui são listados todos os contatos cadastrados no site. Você pode alterar o status e excluir os contatos."
        size="1rem"
        color="#4d4c4c"
      />

      <AdminContatoTable />
    </div>
  );
}
