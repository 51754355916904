import { IconButton } from "@mui/material";
import style from "./style.module.css";
import { Eye, PencilLine, Trash } from "@phosphor-icons/react";
import { STORAGE_URL } from "utilities/variables";
import Swal from "sweetalert2";
import { post } from "utilities/requests";

export default function AdminClienteItem({
  training,
  refetch,
  setModalAction,
  setNewProduct,
  setOpenProductModal,
}) {
  const handleDelete = async () => {
    Swal.fire({
      title: "Tem certeza?",
      text: "Você não poderá reverter isso!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, apagar!",
      cancelButtonText: "Não, cancelar!",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append("id", training.id);
        const res = await post("customers/delete", formData);
        if (res.httpCode === 200) {
          refetch();
          Swal.fire("Apagado!", "O depoimento foi apagado.", "success");
        } else {
          Swal.fire("Erro!", res.message, "error");
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "O depoimento está seguro :)", "error");
      }
    });
  };

  return (
    <tr>
      <td className={style.tdImage}>
        <img src={STORAGE_URL + training.image_path} alt="Imagem da pessoa" />
      </td>
      <td>{training.name}</td>
      <td>{training.link}</td>
      <td
        style={{
          textAlign: "right",
        }}
      >
        <IconButton
          onClick={() => {
            setModalAction("edit");
            setNewProduct(training);
            setOpenProductModal(true);
          }}
          style={{
            cursor: "pointer",
            touchAction: "pan-x",
          }}
        >
          <PencilLine />
        </IconButton>
        <IconButton
          onClick={handleDelete}
          style={{
            cursor: "pointer",
            touchAction: "pan-x",
          }}
          color="error"
        >
          <Trash />
        </IconButton>
      </td>
    </tr>
  );
}
