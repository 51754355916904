import style from "./style.module.css";
import AdminClienteItem from "./ClienteItem";
import { useEffect, useState } from "react";
import { get } from "utilities/requests";
import AdminClientesModal from "./ClientesModal";
import CustomInput from "components/CustomInput";
import form_styles from "components/SpecificStyles/form.module.css";

export default function AdminClientesTable() {
  const [search, setSearch] = useState("");

  const [products, setProducts] = useState([]);

  const getProduct = async () => {
    const res = await get("customers/list");
    setProducts(res);
  };

  const [open_product_modal, setOpenProductModal] = useState(false);
  const [modal_action, setModalAction] = useState("add");
  const [new_product, setNewProduct] = useState({
    avatar_path: "",
    name: "",
    link: "",
  });

  useEffect(() => {
    getProduct();
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          width: "100%",
          paddingTop: "1rem",
          justifyContent: "space-between",
        }}
        className={form_styles.customFormStyle}
      >
        <CustomInput
          placeholder="Pesquisar cliente"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{
            width: "100%",
            maxWidth: "30rem",
          }}
        />

        <input
          type="submit"
          value={"Novo cliente"}
          onClick={() => {
            setOpenProductModal(true);
            setModalAction("add");
            setNewProduct({
              avatar_path: "",
              name: "",
              link: "",
            });
          }}
          style={{
            width: "max-content",
          }}
        />
      </div>
      <AdminClientesModal
        {...{
          visible: open_product_modal,
          setOpen: setOpenProductModal,
          action: modal_action,
          refetch: getProduct,
          new_product,
          setNewProduct,
        }}
      />
      <table className={style.customTable}>
        <thead>
          <tr>
            <th>Avatar</th>
            <th>Name</th>
            <th>Link</th>
            <th>Ações</th>
          </tr>
        </thead>

        <tbody>
          {products.map((training, index) => (
            <AdminClienteItem
              key={index}
              {...{
                training,
                refetch: getProduct,
                setModalAction,
                setNewProduct,
                setOpenProductModal,
              }}
            />
          ))}
          {products.length === 0 && (
            <tr>
              <td
                colSpan={5}
                style={{ textAlign: "center", paddingTop: "4rem" }}
              >
                Nenhum cliente encontrado
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}
