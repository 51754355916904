import { Container } from "@mui/material";
import MVPItem from "./MVPItem";

import t1 from "assets/static/mvp_icons/t1.jpg";
import t2 from "assets/static/mvp_icons/t2.jpg";
import t3 from "assets/static/mvp_icons/t3.jpg";

const { default: CustomSection } = require("components/CustomSection");

const MVPSection = () => {
  return (
    <CustomSection
      style={{
        overflow: "hidden",
        minHeight: "auto",
      }}
      bg_color="var(--background-color0)"
      id="trainings-mvp"
    >
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4rem",
          padding: "6rem 1rem",
        }}
      >
        <MVPItem
          title="Treinamentos Corporativos"
          description="Oferecemos treinamentos personalizados, adaptados às necessidades exclusivas de sua empresa. Esteja você buscando aprimorar habilidades individuais ou fortalecer a coesão da equipe, estamos aqui para guiar sua jornada para o sucesso."
          icon={t1}
        />
        <MVPItem
          title="Treinamentos Industriais"
          description="Nossos treinamentos industriais especializados são projetados para capacitar sua equipe com conhecimentos práticos, promovendo a otimização de processos e impulsionando a produtividade. Transforme desafios em oportunidades de crescimento."
          icon={t2}
          align_left={false}
        />
        <MVPItem
          title="Gestão de Equipes"
          description="Em nossa jornada de treinamentos, concentramo-nos na gestão de equipes, desenvolvendo habilidades de liderança que transcendem os limites do ordinário. Capacite-se para liderar com confiança e inspirar o melhor de sua equipe."
          icon={t3}
        />
      </Container>
    </CustomSection>
  );
};

export default MVPSection;
