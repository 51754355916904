import AdminSideNav from "components/AdminSideNav";
import { Outlet } from "react-router-dom";

export default function DashboardOutlet() {
  return (
    <div
      style={{
        display: "flex",
        minWidth: "100vw",
        minHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          maxWidth: "20rem",
          height: "100vh",
          display: "flex",
        }}
      >
        <AdminSideNav />
      </div>
      <Outlet />
    </div>
  );
}
