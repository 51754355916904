import { Container } from "@mui/system";
import CustomSection from "components/CustomSection";
import SwiperCarousel from "./Swiper";
import { Link } from "react-router-dom";
import { Play } from "@phosphor-icons/react";
import login_button_style from "components/SpecificStyles/login_button.module.css";

const Landing = () => {
  return (
    <CustomSection
      style={{
        overflow: "hidden",
      }}
      bg_color="var(--background-color1)"
      id="home"
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          width: "100vw",
          height: "100vh",
          gap: "5vw",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "120%",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h1
              style={{
                fontSize: "4rem",
                color: "var(--text-color1)",
              }}
            >
              Transforme sua Empresa com Nossos Treinamentos
            </h1>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <p
              style={{
                fontSize: "1.2rem",
                color: "var(--text-color1)",
              }}
            >
              Oferecemos treinamentos corporativos personalizados,
              especialização industrial e desenvolvimento de liderança para
              elevar sua equipe a novos patamares de sucesso.
            </p>
          </div>

          <Link to={"/contato"} className="remove-underline">
            <button
              className={`Transition-1 ${login_button_style.loginButton}`}
              style={{
                letterSpacing: "0.1rem",
                perspective: "1500px",
                position: "relative",
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                height: "3rem",
                backgroundColor: "rgb(var(--accent-color0), 0.3)",
              }}
            >
              <Play weight="fill" className="Transition-1" />
              <span style={{ zIndex: 2, position: "relative" }}>
                Tenho interesse
              </span>
              <div
                className={`${login_button_style.cardFlipEffect} Transition-1`}
              />
            </button>
          </Link>
        </div>
        <SwiperCarousel />
      </Container>
    </CustomSection>
  );
};

export default Landing;
