import { IconButton } from "@mui/material";
import { Check, Eye, PencilLine, Trash } from "@phosphor-icons/react";
import Swal from "sweetalert2";
import { post } from "utilities/requests";
import CustomText from "components/CustomText";
import Modal from "components/Modal";
import { useState } from "react";

export default function AdminDenunciaItem({ training, refetch, is_replied }) {
  const [open_message_modal, setOpenMessageModal] = useState(false);

  const handleReply = async () => {
    Swal.fire({
      title: "Deseja marcar como respondido?",
      text: "Você não poderá reverter isso!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, marcar!",
      cancelButtonText: "Não, cancelar!",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append("id", training.id);
        const res = await post("complaints/reply", formData);
        if (res.httpCode === 200) {
          refetch();
          Swal.fire(
            "Apagado!",
            "O contato foi marcado como respondido.",
            "success"
          );
        } else {
          Swal.fire("Erro!", res.message, "error");
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "O contato está seguro :)", "error");
      }
    });
  };

  const handleDelete = async () => {
    Swal.fire({
      title: "Deseja apagar este contato?",
      text: "Você não poderá reverter isso!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, apagar!",
      cancelButtonText: "Não, cancelar!",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append("id", training.id);
        const res = await post("complaints/delete", formData);
        if (res.httpCode === 200) {
          refetch();
          Swal.fire("Apagado!", "A tag foi apagada com sucesso.", "success");
        } else {
          Swal.fire("Erro!", res.message, "error");
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "A tag está seguro :)", "error");
      }
    });
  };

  return (
    <tr>
      <td>{training.name}</td>
      <td>{training.email}</td>
      <td>
        <div
          style={{
            display: "inline-flex",
            gap: "0.5rem",
          }}
        >
          <Modal
            title={`Mensagem de ${training.name}`}
            open={open_message_modal}
            setOpen={setOpenMessageModal}
            maxWidth="50rem"
          >
            <CustomText
              text={training.message}
              size="1rem"
              justify={"justify"}
            />
          </Modal>
          <CustomText lines={3} text={training.message} size="1rem" />
          <IconButton
            onClick={() => setOpenMessageModal(true)}
            style={{
              cursor: "pointer",
              touchAction: "pan-x",
              fontSize: "1.2rem",
            }}
          >
            <Eye />
          </IconButton>
        </div>
      </td>
      <td>
        {new Date(
          is_replied ? training.updated_at : training.created_at
        ).toLocaleString("pt-BR")}
      </td>
      <td
        style={{
          textAlign: "right",
        }}
      >
        {!training.is_replied && (
          <IconButton
            onClick={() => handleReply()}
            style={{
              cursor: "pointer",
              touchAction: "pan-x",
            }}
            color="success"
          >
            <Check />
          </IconButton>
        )}
        <IconButton
          onClick={handleDelete}
          style={{
            cursor: "pointer",
            touchAction: "pan-x",
          }}
          color="error"
        >
          <Trash />
        </IconButton>
      </td>
    </tr>
  );
}
