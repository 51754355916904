import CustomText from "components/CustomText";
import AdminClientesTable from "./ClientesTable";

export default function AdminClientes() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        overflowY: "auto",
        padding: "3rem 2rem",
      }}
    >
      <CustomText text="Clientes" size="1.4rem" bold />
      <CustomText
        text="Aqui são listados todos os clientes cadastrados no site. Você pode alterar, excluir ou adicionar novos clientes."
        size="1rem"
        color="#4d4c4c"
      />

      <AdminClientesTable />
    </div>
  );
}
