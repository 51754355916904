import { Route, Routes, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Code } from "@phosphor-icons/react";
import DashboardOutlet from "pages/private";
import CustomText from "components/CustomText";
import AdminTreinamentos from "pages/private/Treinamentos";
import AdminContato from "pages/private/Contato";
import AdminDepoimentos from "pages/private/Depoimentos";
import AdminClientes from "pages/private/Clientes";
import AdminTermosDeUso from "pages/private/TermosDeUso";
import AdminPoliticaDePrivacidade from "pages/private/PoliticaDePrivacidade";
import AdminDenuncia from "pages/private/Denuncias";

const PrivateRoutes = () => {
  const CheckIsAdmin = () => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user") || "{}");

    if (token === null) {
      return <Navigate to="/login" />;
    } else {
      return <DashboardOutlet />;
    }

    // if (user?.role == "admin") {
    //   return <DashboardOutlet />;
    // } else {
    //   toast.error("Você não tem permissão para acessar esta página.");
    //   return <Navigate to="/login" />
    // }
  };

  return (
    <>
      <Routes>
        <Route path={"/"} element={<CheckIsAdmin />}>
          <Route path={"/treinamentos"} element={<AdminTreinamentos />} />
          <Route path={"/depoimentos"} element={<AdminDepoimentos />} />
          <Route path={"/clientes"} element={<AdminClientes />} />
          <Route
            path={"/politica-de-privacidade"}
            element={<AdminPoliticaDePrivacidade />}
          />
          <Route path={"/termos-de-uso"} element={<AdminTermosDeUso />} />

          {/* contatos */}
          <Route
            path={"/contatos/aguardando-resposta"}
            element={<AdminContato />}
          />
          <Route path={"/contatos/respondidos"} element={<AdminContato />} />

          {/* denuncias */}
          <Route
            path={"/denuncias/aguardando-resposta"}
            element={<AdminDenuncia />}
          />
          <Route path={"/denuncias/respondidos"} element={<AdminDenuncia />} />
        </Route>
      </Routes>
    </>
  );
};

export default PrivateRoutes;
