import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import "swiper/css";
import "swiper/css/navigation";

import { get } from "utilities/requests";
import { STORAGE_URL } from "utilities/variables";
import CustomText from "components/CustomText";
import { useNavigate } from "react-router-dom";

const TrainingsSection = () => {
  const navigate = useNavigate();
  const [trainings, setTrainigs] = useState([]);
  const getTrainings = async () => {
    const res = await get("trainings/list");
    setTrainigs(res);
  };

  useEffect(() => {
    getTrainings();
  }, []);

  const img_style = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  return (
    <div
      id="mais-informacoes"
      style={{
        width: "100%",
        height: "100vh",
      }}
    >
      <Swiper
        style={{
          width: "100%",
          height: "100%",
        }}
        navigation={true}
        modules={[Navigation]}
        speed={800}
        loop={true}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 2,
          },
        }}
      >
        {/* <SwiperSlide>
          <img style={img_style} src={img1} alt="Imagem do Carousel" />
        </SwiperSlide> */}
        {trainings.map((training, index) => (
          <SwiperSlide
            key={index}
            style={{
              position: "relative",
              cursor: "pointer",
            }}
            onClick={() => navigate(`/contato`)}
          >
            <div
              style={{
                width: "99%",
                position: "absolute",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                bottom: "1%",
                borderRadius: "1rem",
                left: "50%",
                transform: "translateX(-50%)",
                backdropFilter: "blur(4rem)",
                padding: "2rem",
                backgroundColor: "rgba(0,0,0,0.2)",
                boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                border: "1px solid rgba(255, 255, 255, 0.28)",
              }}
            >
              <CustomText
                text={training.title}
                size="2rem"
                color="white"
                bold
              />
              <CustomText
                text={training.text}
                size="1.1rem"
                color="white"
                justify={"justify"}
              />
            </div>
            <img
              style={img_style}
              src={STORAGE_URL + training.image_path}
              alt="Imagem do Carousel"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default TrainingsSection;
