import { Box, Typography } from "@mui/material";
import { Star } from "@phosphor-icons/react";
import CustomText from "components/CustomText";
import Modal from "components/Modal";
import { useEffect, useRef, useState } from "react";
import { STORAGE_URL } from "utilities/variables";

const TestimonialCard = ({ text, name, occupation, company, avatar_path }) => {
  const [open_view_more, setOpenViewMore] = useState(false);
  const [is_ellipsis_active, setIsEllipsisActive] = useState(false);
  const text_ref = useRef(null);

  useEffect(() => {
    setIsEllipsisActive(
      text_ref.current.offsetHeight < text_ref.current.scrollHeight
    );
  }, [text_ref]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        maxWidth: "26rem",
        gap: "1.2rem",
        display: "flex",
        flexDirection: "column",
        margin: "0.4rem 0.4rem",
        transition: "all 0.2s ease-in-out",
        cursor: "pointer",
        overflow: "hidden",
        "&:hover": {
          position: "relative",
          zIndex: 1,
          transform: "scale(1.05)",
        },
        "&:hover .view-more-warning": {
          top: "0!important",
        },
      }}
      onClick={() => setOpenViewMore(true)}
    >
      <div
        className="view-more-warning"
        style={{
          display: is_ellipsis_active ? "block" : "none",
          position: "absolute",
          top: "-2rem",
          right: "0",
          transition: "0.2s ease-in-out",
          backgroundColor: "var(--background-color1)",
          padding: "0.2rem 0.8rem",
          borderRadius: "0.8rem",
        }}
      >
        Ver tudo
      </div>
      <div style={{ color: "#e8c400" }}>
        <Star weight="fill" />
        <Star weight="fill" />
        <Star weight="fill" />
        <Star weight="fill" />
        <Star weight="fill" />
      </div>

      <div
        style={{
          minHeight: "10rem",
          maxHeight: open_view_more ? "100rem" : "10rem",
          transition: "all 0.2s ease-in-out",
        }}
      >
        <p
          ref={text_ref}
          style={{
            display: "-webkit-box",
            WebkitLineClamp: open_view_more ? 100 : 6,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {text}
        </p>
      </div>

      <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <div>
          <img
            style={{
              width: "4rem",
              height: "4rem",
              borderRadius: "0.4rem",
              backgroundPosition: "center",
              backgroundSize: "cover",
              // boxShadow: "0 0 0 0.1rem rgba(105, 59, 159, 1)",
              objectFit: "cover",
            }}
            src={STORAGE_URL + avatar_path}
            alt=""
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            style={{
              fontSize: "1.4rem",
              fontWeight: "bold",
              color: "var(--blue-0)",
            }}
          >
            {name}
          </span>
          <span style={{ fontSize: "1rem", color: "#858585" }}>
            {occupation}
          </span>
        </div>
      </div>
    </Box>
  );
};

export default TestimonialCard;
