import { Box, Modal as MuiModal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function Modal({
  title,
  subtitle,
  children,
  open,
  setOpen,
  maxWidth = "65vw",
}) {
  return (
    <MuiModal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
    >
      <Box
        sx={{
          width: {
            xs: "max-content",
            lg: maxWidth,
          },
          maxWidth: "98vw",
          height: "max-content",
          maxHeight: "80vh",
          backgroundColor: "white",
          display: "flex",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          padding: {
            xs: "1rem",
            md: "3rem",
            lg: "3rem",
          },
          borderRadius: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "0.8rem",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: "4rem",
          }}
        >
          <Typography
            className="text-ellipsis-1-lines"
            variant="h4"
            component="h4"
            sx={{
              color: "#575676",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {title}
          </Typography>

          <CloseIcon
            onClick={() => setOpen(false)}
            style={{
              fontSize: "2rem",
              position: "absolute",
              right: "1rem",
              top: "1rem",
              cursor: "pointer",
            }}
          />
        </div>
        <Typography
          className="text-ellipsis-1-lines"
          variant="p"
          component="p"
          sx={{
            color: "#575676",
            fontWeight: "bold",
          }}
        >
          {subtitle}
        </Typography>

        <div
          style={{
            display: "flex",
            overflowY: "auto",
          }}
        >
          {children}
        </div>
      </Box>
    </MuiModal>
  );
}
