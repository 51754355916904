import style from "./style.module.css";
import AdminContatoItem from "./ContatoItem";
import { useEffect, useState } from "react";
import { get } from "utilities/requests";
import CustomInput from "components/CustomInput";
import form_styles from "components/SpecificStyles/form.module.css";
import { useLocation } from "react-router-dom";

export default function AdminContatoTable() {
  const [search, setSearch] = useState("");
  const location = useLocation();
  const [is_replied, setIsReplied] = useState(false);

  const [products, setProducts] = useState([]);

  const getContatos = async () => {
    if (location.pathname.includes("respondidos")) {
      const res = await get("contact-us/list?replied=true");
      setProducts(res.news);
      setIsReplied(true);
    } else {
      const res = await get("contact-us/list?replied=false");
      setProducts(res.news);
      setIsReplied(false);
    }
  };

  const [open_product_modal, setOpenProductModal] = useState(false);
  const [modal_action, setModalAction] = useState("add");
  const [new_product, setNewProduct] = useState({
    title: "",
    text: "",
    image: "",
  });

  useEffect(() => {
    getContatos();
  }, [location]);

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          width: "100%",
          paddingTop: "1rem",
          justifyContent: "space-between",
        }}
        className={form_styles.customFormStyle}
      >
        <CustomInput
          placeholder="Pesquisar contato"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{
            width: "100%",
            maxWidth: "30rem",
          }}
        />
      </div>
      <table className={style.customTable}>
        <thead>
          <tr>
            <th>Nome</th>
            <th>E-mail</th>
            <th>Telefone</th>
            <th>Assunto</th>
            <th>Mensagem</th>
            {/* {is_replied && <th>Data de resposta</th>} */}
            <th>{is_replied ? "Data de resposta" : "Data de envio"}</th>
            <th>Ações</th>
          </tr>
        </thead>

        <tbody>
          {products.map((training, index) => (
            <AdminContatoItem
              key={index}
              {...{
                training,
                refetch: getContatos,
                setModalAction,
                setNewProduct,
                setOpenProductModal,
                is_replied,
              }}
            />
          ))}
          {products.length === 0 && (
            <tr>
              <td
                colSpan={5}
                style={{ textAlign: "center", paddingTop: "4rem" }}
              >
                Nenhum contato encontrado
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}
