import CustomText from "components/CustomText";
import RichEditor from "components/RichEditor.jsx";
import { useEffect, useState } from "react";
import form_styles from "components/SpecificStyles/form.module.css";
import { get, post } from "utilities/requests";
import { toast } from "react-toastify";

export default function AdminPoliticaDePrivacidade() {
  const [text, setText] = useState("");
  const [ready_to_render, setReadyToRender] = useState(false);

  const handleUpdatePoliticaDePrivacidade = async () => {
    const formData = new FormData();
    formData.append("privacy_policy", text);

    const res = await post("privacy-policy/update", formData);

    if (res.httpCode === 200) {
      toast.success("Política de privacidade atualizada com sucesso");
    } else {
      toast.error(res.message || "Erro ao atualizar a política de privacidade");
    }
  };

  const getPrivacyPolicy = async () => {
    const res = await get("privacy-policy");
    setText(res.privacy_policy || "");
    setReadyToRender(true);
  };

  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        padding: "3rem 2rem",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "1rem",
          marginBottom: "2rem",
          justifyContent: "space-between",
        }}
        className={form_styles.customFormStyle}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CustomText text="Política de Privacidade" size="1.4rem" bold />
          <CustomText
            text="Aqui são listados todos as políticas de privacidade cadastrados no site. Você pode alterar, excluir ou adicionar novas políticas de privacidade."
            size="1rem"
            color="#4d4c4c"
            justify={"justify"}
          />
        </div>
        <input
          type="submit"
          value={"Salvar"}
          onClick={() => handleUpdatePoliticaDePrivacidade()}
          style={{
            width: "max-content",
          }}
        />
      </div>

      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "hidden",
        }}
      >
        {ready_to_render && (
          <RichEditor
            placeholder="Digite aqui o termo de uso"
            text={text}
            setText={setText}
          />
        )}
      </div>
    </div>
  );
}
