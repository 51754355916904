import { Container, Typography } from "@mui/material";
import CustomSection from "components/CustomSection";
import { useEffect, useState } from "react";
import TestimonialCard from "./TestimonialCard";
import { get } from "utilities/requests";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/navigation";

const responsive = {
  0: {
    items: 1,
  },
  480: {
    items: 1,
  },
  780: {
    items: 2,
  },
  1300: {
    items: 3,
  },
};

const Depoimentos = () => {
  const [testimonies, setTestimonies] = useState([]);
  const [readyToRender, setReadyToRender] = useState(true);

  const getProduct = async () => {
    const res = await get("testimonials/list");
    setTestimonies(res);
    setReadyToRender(true);
  };

  useEffect(() => {
    getProduct();
  }, []);

  return (
    readyToRender &&
    testimonies.length > 0 && (
      <CustomSection
        style={{
          overflow: "hidden",
          position: "relative",
          minHeight: "auto",
        }}
        id="depoimentos"
      >
        <Container
          maxWidth="xl"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1.4rem",
            margin: "auto",
            padding: "8rem 0",
            width: "100vw",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              color: "var(--green-0)",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Depoimentos
          </Typography>

          <Typography
            variant="p"
            sx={{
              color: "var(--text-color1)",
              fontSize: "1.1rem",
              textAlign: "center",
              margin: "0 auto",
              marginBottom: "4rem",
              fontWeight: "normal",
              maxWidth: "30rem",
            }}
          >
            O que nossos clientes e parceiros falam de nós
          </Typography>

          <Swiper
            spaceBetween={30}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              480: {
                slidesPerView: 1,
              },
              780: {
                slidesPerView: 2,
              },
              1300: {
                slidesPerView: 3,
              },
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Autoplay]}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            style={{
              width: "100%",
            }}
          >
            {testimonies.map((testimony, index) => (
              <SwiperSlide>
                <TestimonialCard key={index} {...testimony} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </CustomSection>
    )
  );
};

export default Depoimentos;
