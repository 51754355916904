import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertFromHTML, convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";

export default function RichEditor({
  text,
  setText,
  placeholder = "Digite aqui o texto",
  toolbarHidden = false,
}) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  // sanitize html
  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  // convert editor state to html and sanitize it
  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setText(createMarkup(html).__html);
  }, [editorState]);

  // initialize editor with text
  useEffect(() => {
    setEditorState(EditorState.createWithContent(convertFromHTML(text)));
  }, []);

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={setEditorState}
      toolbarHidden={toolbarHidden}
      placeholder={placeholder}
      wrapperClassName="wrapper-class"
      editorClassName="editor-class"
      toolbarClassName="toolbar-class"
    />
  );
}
