import { Icon, Typography } from "@mui/material";
import { Container } from "@mui/system";
import CustomSection from "components/CustomSection";
import form_styles from "components/SpecificStyles/form.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { post } from "utilities/requests";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CustomInput from "components/CustomInput";
import EmailIcon from "@mui/icons-material/Email";
import SwiperCarousel from "../Home/Swiper";

const LoginPage = () => {
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let form = new FormData();
    form.append("email", email);
    form.append("password", password);

    const res = await post("auth/login", form);

    if (res?.status === true) {
      localStorage.setItem("token", res.access_token);
      localStorage.setItem("user", JSON.stringify(res.user));
      setTimeout(() => {
        navigate("/dashboard/treinamentos");
      }, 1000);
    } else {
      let showed = false;

      if (res?.errors?.password) {
        toast.error(res.errors.password[0]);
        showed = true;
      }
      if (res?.errors?.email) {
        toast.error(res.errors.email[0]);
        showed = true;
      }

      try {
        toast.error(res.errors[0]);
        showed = true;
      } catch {}

      if (!showed) toast.error("Erro ao fazer login. Consulte o Suporte.");
    }
  };

  return (
    <CustomSection
      style={{
        overflow: "hidden",
        backgroundColor: "black",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 2,
          color: "white",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Entrar no sistema
        </Typography>

        <Typography
          variant="p"
          sx={{
            fontSize: "1.1rem",
            textAlign: "center",
            margin: "0 auto",
            fontWeight: "normal",
            maxWidth: "30rem",
          }}
        >
          Já é aluno e não tem acesso? Entre em contato com o suporte para
          receber suas credenciais!
        </Typography>

        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "2rem",
            width: "100%",
            maxWidth: "30rem",
          }}
          className={form_styles.customFormStyle}
          onSubmit={handleSubmit}
        >
          <CustomInput
            type="email"
            placeholder="E-mail"
            icon={EmailIcon}
            value={email}
            onChange={setEmail}
            required
          />

          <CustomInput
            type={showPassword ? "text" : "password"}
            placeholder="Senha"
            icon={showPassword ? VisibilityOffIcon : VisibilityIcon}
            value={password}
            onChange={setPassword}
            required
            onClick={() => setShowPassword(!showPassword)}
          />

          {/* <div
            style={{
              position: "relative",
            }}
          >
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Icon
              sx={{
                position: "absolute",
                right: "1rem",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowPassword(!showPassword)}
              component={showPassword ? VisibilityOffIcon : VisibilityIcon}
            />
          </div> */}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <input type="submit" />
          </div>

          <Typography
            variant="p"
            sx={{
              fontSize: "1rem",
              textAlign: "center",
              fontWeight: "normal",
            }}
          >
            Ainda não é aluno? Não perca tempo e{" "}
            <b>
              <a href="/contato">fale com um dos nossos consultores</a>
            </b>{" "}
            agora mesmo!
          </Typography>
        </form>
      </Container>

      <div
        style={{
          display: "flex",
          width: "100vw",
          height: "100vh",
        }}
      >
        <SwiperCarousel
          forced_style={{
            height: "100vh",
            borderRadius: "0",
            boxShadow: "none",
            border: "none",
            outline: "none",
            opacity: 0.5,
          }}
          slide_speed={4000}
          slide_delay={8000}
        />
      </div>
    </CustomSection>
  );
};

export default LoginPage;
