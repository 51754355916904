export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const image = file;
    if (!(file instanceof File)) return;
    if (image && FileReader) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    }
  });

export const toggleAppTheme = () => {
  const app = document.querySelector("body");

  if (app.className.includes("dark-mode")) {
    app.classList.remove("dark-mode");
    app.classList.add("white-mode");
    return "white-mode";
  } else {
    app.classList.add("dark-mode");
    app.classList.remove("white-mode");
    return "dark-mode";
  }
};

export const handleLogout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  // localStorage.removeItem("first_access");
  // dispatch({ type: "login", payload: { token: null, user: null } });
  // dispatch({ type: "cart_items", payload: null });
  window.location.href = "/";
};
