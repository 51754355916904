import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { get } from "utilities/requests";

export default function TermosDeUsoPage() {
  const [text, setText] = useState("");

  const getTermsOfUse = async () => {
    const res = await get("terms-of-use");
    setText(res.terms_of_use || "");
  };

  useEffect(() => {
    getTermsOfUse();
  }, []);

  return (
    <div
      style={{
        backgroundColor: "var(--background-color1)",
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4rem",
          padding: "6rem 1rem",
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </Container>
    </div>
  );
}
