import CustomText from "components/CustomText";
import AdminDenunciaTable from "./DenunciaTable";

export default function AdminDenuncia() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        overflowY: "auto",
        padding: "3rem 2rem",
      }}
    >
      <CustomText text="Denúncias" size="1.4rem" bold />
      <CustomText
        text="Aqui são listados todas as denúncias feitas pelos usuários. Você pode alterar o status e excluir as denúncias."
        size="1rem"
        color="#4d4c4c"
      />

      <AdminDenunciaTable />
    </div>
  );
}
