import Landing from "./Landing";
import MVPSection from "./MVPSection";
import Clientes from "./Clientes";
import Contato from "../Contato";
import AboutMe from "./AboutMe";
import TrainingsSection from "./Trainings";
import Depoimentos from "./Depoimentos";
// import Servicos from "./Servicos";
// import SobreNos from "./SobreNos";
// import Clientes from "./Clientes/Clientes";
// import Contato from "../Contato";

const HomePage = () => {
  return (
    <>
      <Landing />
      <MVPSection />
      <TrainingsSection />
      {/* <AboutMe /> */}
      <Clientes />
      <Depoimentos />
      <Contato invert_bg_theme section_id="contato" />
      {/* <SobreNos /> */}
      {/* <Servicos {...{landing_data}} /> */}
      {/* clientes + depoimentos (mesma secao) sem o texto do depoimentos */}
    </>
  );
};

export default HomePage;
